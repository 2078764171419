// Here you can add other styles
.react-toast-notifications__container {
  z-index: 9999 !important;
}

/* left side main menu */
html:not([dir=rtl]) .sidebar-nav .nav-group-items .nav-link {
  padding-left: 2rem;
}
.sidebar .material-icons, .sidebar .material-icons-outlined, .sidebar .material-icons-sharp, .sidebar .material-icons-round {
  padding: 0 20px 0 0;
  font-weight: 100;
}
.sidebar-fold-toggler {
  cursor: pointer;
  color: #fff !important;
  display: flex !important;
  flex: 0 0 3rem;
  justify-content: flex-end;
  padding: 0;
  width: 100%;
  border: 0;
}
.sidebar-fold-toggler:before {
  display: block;
  width: 4rem;
  height: 3rem;
  content: "";
  background-image: var(--cui-sidebar-toggler-indicator,url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%238a93a2' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E"));
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 0.75rem;
  transition: transform .15s;
}
.fold.sidebar-fold-toggler:before {
  transform: rotate(180deg);
}

/* system default tab UI */
div[role="tabpanel"] {
  background-color: #fff;
}
.tabtab-modal {
  position: relative;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-50%);
}

.button-icon {
  flex: 0 0 4rem;
  height: 1.25rem;
  font-size: 1.25rem;
  text-align: center;
  fill: currentColor;
  transition: inherit;
}
.btn-whatsapp {
  color: #fff;
  background-color: #1ad03f;
  border-color: #1ad03f;
}
.btn-whatsapp:focus, .btn-whatsapp:hover {
  color: #fff;
  background-color: #51c65e;
  border-color: #51c65e;
}

.disabled-button,
.disabled-button:hover,
.disabled-button:focus,
.disabled-button:active {
  background: #F7F7F8;
  border: 0;
  color: #CCCCCC;
}
.disabled-button:active:focus{
  box-shadow: none;
}

.info {
  position: relative;
  display: inline-block;
}
.info .tooltiptext {
  visibility: hidden;
  min-width: 180px;
  width: 20vw;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  bottom: 125%;
  right: -50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.info .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  right: 5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
.info:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.info img {
  width: 15px;
  height: 15px;
}
.info-float-right{
  float: right;
}
.tabtab-list-container li {
  max-width: calc(100% / 4);
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100px;
  text-align: center;
}
@media screen and (max-width: 1260px) {
  .dashboard-widget-title{
    font-size: 12px;
  }
}

.draft-row {
  background-color: #fde8b9;
}
.tox-editor-container {
  z-index: 0;
}
