.wrapper {
  width: 100%;
  @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0));
  will-change: auto;
  @include transition(padding .15s);
}

.repeatable-component-group-container {
  border: solid 1px #94a3bb;
}

/* main menu bar */
.sidebar * {
  color: #fff !important;
}
.sidebar {
  background-color: #0A3664 !important;
}
.simplebar-content > li {
  background-color: #0C3865 !important;
}
.simplebar-content > li.show {
  background-color: #306497 !important;
}
.simplebar-content > li ul.nav-group-items > li {
  background-color: #4288CD !important;
}
.simplebar-content > li ul.nav-group-items > li.nav-item > a.active {
  background-color: #5597D8 !important;
}
.simplebar-content > li ul.nav-group-items > li.show, .simplebar-content > li ul.nav-group-items > li.show ul > li {
  background-color: #5597D8 !important;
}
.simplebar-content > li ul.nav-group-items > li.show ul > li > a.active {
  background-color: #67B0F8 !important;
}
.simplebar-content > li ul.nav-group-items > li.show ul > li {
  padding-left: 1rem;
}
.simplebar-content > li ul.nav-group-items > li.show ul > li > a {
  white-space: pre-wrap;
}
.sidebar-active-be {
  border: solid 1px rgb(255 255 255 / 10%);
}
.sidebar-active-be > a {
  background-color: initial !important;
}
.sidebar-brand {
  flex: 0 0 3rem;
  background-color: #fff;
}
.header.header-sticky {
  border-left: var(--cui-header-border-width, 1px) solid var(--cui-header-border-color, #d8dbe0);
}
.header-nav {
  align-items: center;
}
.header-nav a > svg {
  color: #CCCCCC !important;
}

.avatar-img {
  border-radius: 0;
}
a.dropdown-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* system breadcrumb */
html:not([dir=rtl]) .breadcrumb-item + .breadcrumb-item::before {
  content: url("data:image/svg+xml,%3Csvg id='icon_right' xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath id='Icon_metro-chevron-left' data-name='Icon metro-chevron-left' d='M18.586,11.377l-1.094-1.094-4.638,4.638,4.638,4.638,1.094-1.094-3.544-3.544Z' transform='translate(23.72 22.921) rotate(180)' fill='%23ccc'/%3E%3Crect id='Rectangle_102' data-name='Rectangle 102' width='16' height='16' fill='none'/%3E%3C/svg%3E%0A");
  position: relative;
  top: 3px;
}
li.breadcrumb-item a {
  color: #CCCCCC;
  text-decoration: none;
}
li.breadcrumb-item a:hover {
  color: #303C54;
  text-decoration: underline;
}
li.breadcrumb-item.active {
  color: #303C54;
}

/* general form UI */
.form-label {
  color: #000;
  white-space: break-spaces;
}
.static-text-info {
  padding: 10px 20px;
  color: #303C54;
}
.form-layout-container.layout-1 {
  display: block;
}
.form-layout-container.layout-1-1,
.form-layout-container.layout-2-1,
.form-layout-container.layout-1-1-1,
.form-layout-container.layout-1-1-2 {
  display: flex;
}
.form-layout-container > .form-layout-element {
  width: 100%;
}
.form-layout-container.layout-1 > .form-layout-element {
  width: 100%;
}
.form-layout-container.layout-1-1 > .form-layout-element:first-child,
.form-layout-container.layout-1-1 > .form-layout-element:nth-child(2) {
  width: 50%;
}
.form-layout-container.layout-2-1 > .form-layout-element:first-child {
  width: 66.7%;
}
.form-layout-container.layout-2-1 > .form-layout-element:nth-child(2) {
  width: 33.3%;
}
.form-layout-container.layout-1-1 > .form-layout-element:first-child,
.form-layout-container.layout-2-1 > .form-layout-element:first-child{
  padding-right: 0.5rem;
}
.form-layout-container.layout-1-1 > .form-layout-element:nth-child(2),
.form-layout-container.layout-2-1 > .form-layout-element:nth-child(2){
  padding-left: 0.5rem;
}
.form-layout-container.layout-1-1-1 > .form-layout-element:first-child,
.form-layout-container.layout-1-1-1 > .form-layout-element:nth-child(2),
.form-layout-container.layout-1-1-1 > .form-layout-element:nth-child(3) {
  width: 33%;
}
.form-layout-container.layout-1-1-1 > .form-layout-element:first-child {
  padding-right: 0.5rem;
}
.form-layout-container.layout-1-1-1 > .form-layout-element:nth-child(2) {
  padding: 0 0.5rem;
}
.form-layout-container.layout-1-1-1 > .form-layout-element:nth-child(3) {
  padding-left: 0.5rem;
}
.form-layout-container.layout-1-1-2 > .form-layout-element:first-child,
.form-layout-container.layout-1-1-2 > .form-layout-element:nth-child(2) {
  width: 25%;
}
.form-layout-container.layout-1-1-2 > .form-layout-element:nth-child(3) {
  width: 50%;
}
.form-layout-container.layout-1-1-2 > .form-layout-element:first-child {
  padding-right: 0.5rem;
}
.form-layout-container.layout-1-1-2 > .form-layout-element:nth-child(2) {
  padding: 0 0.5rem;
}
.form-layout-container.layout-1-1-2 > .form-layout-element:nth-child(3) {
  padding-left: 0.5rem;
}

.form-switch-xl {
  min-height: 36px;
}
.form-switch-xl > input {
  min-width: 3em;
}

.btn-primary {
  background-color: #3B4B64;
  border-color: #3B4B64;
  height: fit-content;
}
.btn-primary:hover {
  background-color: #6782ab;
  border-color: #6782ab;
}

.listing-table-header-field-label {
  cursor: pointer;
  user-select: none;
}

.label-row {
  display: inline-flex;
  width: 100%;
}
.label-row-remarks {
  display: inline-flex;
  margin-left: auto;
  max-width: 70%;
  a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .label-row-remarks-options {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
     a {
       margin: 0 5px;
     }
  }
}

/* add system mobile UI handling */
@media (max-width: 500px) {
  /*.container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }*/
  .form-layout-container.layout-1-1,
  .form-layout-container.layout-1-1-1,
  .form-layout-container.layout-1-1-2 {
    display: block;
  }
  .form-layout-container.layout-1-1 > .form-layout-element,
  .form-layout-container.layout-1-1-1 > .form-layout-element,
  .form-layout-container.layout-1-1-2 > .form-layout-element {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.card {
  margin-top: 1rem;
}

/* listing page */
.listing-filter-adder-container {
  position: relative;
  border: 1px solid rgb(220, 220, 228);
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  border-radius: 4px;
  outline: none;
}
.listing-filter-adder-container:hover {
  background-color: rgb(246, 246, 249);
}
.listing-filter-adder-container > img {
  padding: 8px 16px;
  cursor: pointer;

}
.listing-filters-container {
  display: flex;
  gap: 0.6rem;
  flex-wrap: wrap;
  margin: 1rem 1rem 1rem 0;
}
.listing-filter-condition {
  display: flex;
  align-items: center;
  background: rgb(240, 240, 255);
  padding-right: 12px;
  padding-left: 12px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(217, 216, 255);
}
.listing-filter-cross {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid rgb(217, 216, 255);
}
.listing-filter-cross img {
  height: 0.6rem;
  cursor: pointer;
}
.listing-filter-adder-toggle-container {
  position: absolute;
  top: 100%;
  box-shadow: rgb(33 33 52 / 10%) 0px 1px 4px;
  z-index: 4;
  border: 1px solid rgb(234, 234, 239);
  background: rgb(255, 255, 255);
  padding: 4px;
  border-radius: 4px;
  margin: 8px 0;
}
.adder-toggle-inner-container {
  padding: 12px;
  width: 30vw;
  display: flex;
  flex-direction: column;
  gap: 13px;
}
button.listing-adder-submit-button {
  align-items: center;
  justify-content: center;
  display: flex;
}
.listing-adder-submit-button img {
  margin-right: 7px;
  height: 0.8rem;
}

/* pagination*/
.theme-listing-pagination {
  margin-top: 1rem;
  display: flex;
}
.entries-per-page-selector-container {
  display: flex;
  align-items: center;
  gap: .5rem;
}
.entries-per-page-selector-container > div {
  max-width: 10rem;
}
.theme-listing-pagination nav {
  margin-left: auto;
}
.theme-listing-pagination nav ul {
  display: flex;
}
.theme-listing-pagination nav li {
  list-style: none;
  padding: 12px;
  border-radius: 4px;
  text-decoration: none;
}
.theme-listing-pagination nav li:not(.pagination-current-page):not(.pagination-dot) {
  cursor: pointer;
}
.theme-listing-pagination nav li:not(.pagination-current-page):not(.pagination-dot):not(.pagination-arrow):hover {
  box-shadow: rgb(33 33 52 / 10%) 0 1px 4px;
}
.theme-listing-pagination nav li.pagination-current-page {
  background-color: #e6e6e6;
}

.alert {
  margin-top: 1rem;
}

.select-details-button{
  display: flex;
  align-items: center;
  background: #f0f0ff;
  padding: 3px 10px;
  font-size: 0.8rem;
  margin: 3px;
  color: #591ff9;
  border-radius: 2px;
  cursor: pointer;
}

.changed-successfully {
  -webkit-animation-name: successfulGrow;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-name: successfulGrow;
  -moz-animation-duration: 1.5s;
  -moz-animation-timing-function: ease-in;
  animation-name: successfulGrow;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}
.change-failed {
  -webkit-animation-name: failGrow;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-timing-function: ease-in;
  -moz-animation-name: failGrow;
  -moz-animation-duration: 1.5s;
  -moz-animation-timing-function: ease-in;
  animation-name: failGrow;
  animation-duration: 1.5s;
  animation-timing-function: ease-in;
}

@-webkit-keyframes successfulGrow {
  0% {
    box-shadow: 0 0 0 0.25rem #1fdb4900;
  }
  35% {
    box-shadow: 0 0 0 0.25rem #1fdb49b3;
  }
  100% {
    box-shadow: 0 0 0 0.25rem #1fdb4900;
  }
}
@-moz-keyframes successfulGrow {
  0% {
    box-shadow: 0 0 0 0.25rem #1fdb4900;
  }
  35% {
    box-shadow: 0 0 0 0.25rem #1fdb49b3;
  }
  100% {
    box-shadow: 0 0 0 0.25rem #1fdb4900;
  }
}
@keyframes successfulGrow {
  0% {
    box-shadow: 0 0 0 0.25rem #1fdb4900;
  }
  35% {
    box-shadow: 0 0 0 0.25rem #1fdb49b3;
  }
  100% {
    box-shadow: 0 0 0 0.25rem #1fdb4900;
  }
}
@-webkit-keyframes failGrow {
  0% {
    box-shadow: 0 0 0 0.25rem #da393900;
  }
  35% {
    box-shadow: 0 0 0 0.25rem #da3939b3;
  }
  100% {
    box-shadow: 0 0 0 0.25rem #da393900;
  }
}
@-moz-keyframes failGrow {
  0% {
    box-shadow: 0 0 0 0.25rem #da393900;
  }
  35% {
    box-shadow: 0 0 0 0.25rem #da3939b3;
  }
  100% {
    box-shadow: 0 0 0 0.25rem #da393900;
  }
}
@keyframes failGrow {
  0% {
    box-shadow: 0 0 0 0.25rem #da393900;
  }
  35% {
    box-shadow: 0 0 0 0.25rem #da3939b3;
  }
  100% {
    box-shadow: 0 0 0 0.25rem #da393900;
  }
}
